import type { PropsWithChildren } from 'react';
import { cn } from '@kota/utils';
import * as Popover from '@radix-ui/react-popover';

type Props = PropsWithChildren & {
  trigger: JSX.Element;
  variant?: 'default' | 'naked';
  disabled?: boolean;
};

export default function PopoverComponent({ trigger, children, variant = 'default', disabled }: Props) {
  return (
    <Popover.Root>
      <Popover.Trigger disabled={disabled}>{trigger}</Popover.Trigger>

      <Popover.Portal>
        <Popover.Content
          className={cn({
            'mt-3 max-w-md rounded-lg bg-gray-900 p-3 text-sm text-white': variant === 'default',
            'mt-3 rounded-lg border bg-white shadow-sm': variant === 'naked',
          })}
        >
          <div>{children}</div>
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
}
